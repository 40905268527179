/** @jsx jsx */
import { jsx } from "theme-ui"
import { TheLayout, FindSpace } from "x"

const SearchPage = () => (
  <TheLayout seoTitle="Search Listings" sx={{ bg: "background" }}>
    <FindSpace />
  </TheLayout>
)

export default SearchPage
